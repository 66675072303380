<template>

  <div class="pb">

    <h1 class="ui header centered">
      {{ $t('notes_view_title') }}
    </h1>



    <div 
      v-if="notifications_data?.length"
      class="notifications">
        
      <template v-for="(pro) in notifications_data">
        <NotificationItem
          :notification="pro"
          />
      </template>
      
      <client-only>
        <InfinityScroll 
          :is-loading="is_loading"
          ref="el_infinity_scroll"/>
      </client-only>

    </div>

  </div>

</template>






<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'
import InfinityScroll from '~/components/InfinityScroll.vue';


const start = ref(0)
const limit = ref(20)
const is_ended = ref(false)
// const is_loading = ref(false)
const {$api, $adv, $ga} = useNuxtApp()
const el_infinity_scroll = ref(null)
// const notifications_data = ref([])




const loadData = async function() {     
  const response = await $api.getNotifications(
    useAuth().getId(),
    start.value,
    limit.value,
    1
  )  
  return response;  
}


const { pending: is_loading,  data: notifications_data } = await useLazyAsyncData('notifications_data', async () => await loadData())


// onMounted(async () => {
//   console.log('mounted');
//   if(import.meta.client)
//     notifications_data.value = await loadData();
// })


watchArray([start], async ([new_start], added, removed) => {
  console.log('watcher');

  if(new_start == 0){
    notifications_data.value = [];
    is_ended.value = false;
  }
  
  is_loading.value = true;

  var dt = await loadData();

      
  if(dt.length == 0)
    is_ended.value = true;
  
  notifications_data.value = [...notifications_data.value, ...dt]  

  is_loading.value = false;

}, {deep: true})




useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)



onMounted(() => {
  useUserUpdatesNotifications().value = null;
})




</script>





<style scoped>


.last_update{
    text-align: center;
    padding: 0rem 1.5rem 1.5rem 1.5rem;
    color: #cacaca;
    font-weight: bold;
    margin-top: 20px;
}

</style>
